/*jshint sub:true*/ // options.transient will not work, has to be options['transient']
/**
 * Copyright (C) SiteVision AB 2002-2019, all rights reserved
 *
 * @author albin
 */
import $ from '@sv/jquery';
import * as events from './events';

var $messageDiv = $('<div/>', {
  class: 'bootstrap sv-notifier',
});

var removeMessages = function ($messages) {
  $messages.fadeOut('slow', function () {
    $(this).remove();
  });
};

var removeTransientMessages = function () {
  removeMessages($messageDiv.find('.sv-fn-transient-message'));
};

var createMessageContainer = function (type, heading, message) {
  var $messageContainer = $('<div></div>', {
    class: 'alert',
    css: {
      lineHeight: '20px',
      fontSize: '14px',
    },
  }).append(
    '<button type="button" class="close" data-dismiss="alert">×</button>'
  );

  $messageContainer.addClass('alert-block alert-' + type);

  if (heading) {
    var headingTag = $('<p/>'),
      strong = $('<strong/>');

    strong.text(heading);
    headingTag.append(strong);
    $messageContainer.append(headingTag);
  }

  if (message) {
    var messageTag = $('<p/>');

    messageTag.text(message);
    $messageContainer.append(messageTag);
  }

  return $messageContainer;
};

var appendMessage = function (options) {
  var $newMessage = createMessageContainer(
    options.type,
    options.heading,
    options.message
  );

  removeTransientMessages();

  if (options.onClick) {
    $newMessage.on('click', ':not(.close)', options.onClick);
  }

  if (options['transient']) {
    $newMessage.addClass('sv-fn-transient-message');
  }

  if (options.timer) {
    setTimeout(function () {
      removeMessages($newMessage);
    }, options.timer * 1000);
  }

  if ($messageDiv.parent().length === 0) {
    $messageDiv.appendTo('body');
  }

  $newMessage.prependTo($messageDiv);
};

events.on(events.types.notifyUser, appendMessage);
