/**
 * Copyright (C) SiteVision AB 2002-2012, all rights reserved
 *
 * log handler, sending messages to the console (if available)
 *
 * @author micke
 */
import sv from '@sv/core';

let console;
const traceLevels = ['debug', 'info', 'warn', 'error'],
  traceLevel = {
    debug: false,
    info: true,
    warn: true,
    error: true,
  };

/**
 * IE 8 and below does not support indexOf on arrays
 */
var indexOfWorkAround = function (arr, lookFor) {
  var i;
  if (arr.indexOf) {
    return arr.indexOf(lookFor);
  }

  i = arr.length - 1;
  for (; i >= 0; i--) {
    if (arr[i] === lookFor) {
      return i;
    }
  }

  return -1;
};

(function () {
  var i = 0,
    l = traceLevels.length,
    level;
  if (typeof window.console === 'undefined') {
    console = {
      log: function () {},
      debug: function () {},
      info: function () {},
      warn: function () {},
      error: function () {},
    };
  } else {
    console = window.console;
  }

  for (; i < l; i++) {
    level = traceLevels[i];
    if (typeof console[level] === 'undefined') {
      console[level] = console.log;
    }
  }
})();

export const getTraceLevels = function () {
  return traceLevel;
};

export const setTraceLevel = function (level) {
  var index = indexOfWorkAround(traceLevels, level),
    i = 0,
    l = traceLevels.length;
  for (; i < l; i++) {
    level = traceLevels[i];
    traceLevel[level] = i >= index;
  }
};

var print = function (level, component, message) {
  try {
    if (typeof component === 'string' && !message) {
      message = component;
    }

    if (traceLevel[level]) {
      // eslint-disable-next-line no-prototype-builtins
      if (component && component.hasOwnProperty('toString')) {
        console[level](component.toString() + ' : ' + message);
      } else {
        console[level](message);
      }
    }
  } catch (e) {
    /* Should not throw any errors */
  }
};

export const debug = function (component, message) {
  print(traceLevels[0], component, message);
};

export const info = function (component, message) {
  print(traceLevels[1], component, message);
};

export const warn = function (component, message) {
  print(traceLevels[2], component, message);
};

export const error = function (component, message) {
  print(traceLevels[3], component, message);
};

(function () {
  sv.PageContext.dev ? setTraceLevel('debug') : setTraceLevel('error');
})();

sv.Log = {
  debug,
  info,
  warn,
  error,
  getTraceLevels,
};
