/**
 * Copyright (C) SiteVision AB 2002-2019, all rights reserved
 *
 * @author albin
 */
import $ from '@sv/jquery';
import sv from '@sv/core';
import _ from '@sv/underscore';
import * as i18n from './i18n';

$.ajaxSetup({
  beforeSend: function (xhr, settings) {
    if (
      (settings.type === 'POST' ||
        settings.type === 'PUT' ||
        settings.type === 'DELETE') &&
      !_.isEmpty(sv.PageContext.csrfToken)
    ) {
      xhr.setRequestHeader('X-CSRF-Token', sv.PageContext.csrfToken);
    }
  },
});

function sendRequest(options) {
  if (
    !/get/i.test(options.type) &&
    options.data &&
    $.isPlainObject(options.data)
  ) {
    options.processData = false;
    options.data = JSON.stringify(options.data);
  }

  return $.ajax(options);
}

const BASE_OPTIONS = {
    type: 'get',
    dataType: 'json',
    headers: {
      'Accept-Language': i18n.getLanguage(),
    },
  },
  JSON_OPTIONS = $.extend(
    {},
    {
      contentType: 'application/json; charset=utf-8',
    },
    BASE_OPTIONS
  ),
  DEFAULT_OPTIONS = {
    get: $.extend({}, BASE_OPTIONS),
    put: $.extend({}, JSON_OPTIONS, { type: 'put' }),
    post: $.extend({}, JSON_OPTIONS, { type: 'post' }),
    delete: $.extend({}, JSON_OPTIONS, { type: 'delete' }),
  };

export function doGet(options) {
  return sendRequest($.extend({}, DEFAULT_OPTIONS.get, options));
}

export function doPut(options) {
  return sendRequest($.extend({}, DEFAULT_OPTIONS.put, options));
}

export function doPost(options) {
  return sendRequest($.extend({}, DEFAULT_OPTIONS.post, options));
}

export function doDelete(options) {
  return sendRequest($.extend({}, DEFAULT_OPTIONS['delete'], options));
}

// This is legacy in order to no break anything
sv.Ajax = {
  doGet,
  doPut,
  doPost,
  doDelete,
};
