(function(CSS) {
  "use strict";
  if (!CSS || !CSS.supports("color", "var(--env-font-color)")) {
    import('css-vars-ponyfill').then(({ default: cssVars }) => {
      cssVars({
        silent: true,
        watch: false,
        include: 'style,link[rel="stylesheet"]:not([href*="//"]):not([href^="data:text"])'
      });
    })
  }
})(window.CSS);