/**
 * Copyright (C) SiteVision AB 2002-2012, all rights reserved
 *
 * utils related to a sitevision model object
 *
 * @author micke
 * @depends log.js
 */
import sv from '@sv/core';
import * as log from './log';

const prefix = 'svid';

export const getObjectId = function (id) {
  if (!id) {
    return null;
  }

  try {
    return id.substring(prefix.length).replace('_', '.');
  } catch (e) {
    log.error(this, 'Message: ' + e);
  }
};

export const getHtmlId = function (id) {
  if (!id) {
    return null;
  }

  try {
    return prefix + id.replace('.', '_');
  } catch (e) {
    log.error(this, 'Message: ' + e);
  }
};

sv.ObjectUtil = {
  getObjectId: getObjectId,
  getHtmlId: getHtmlId,
};
