/*jshint unused: false */
/**
 * Copyright (C) SiteVision AB 2002-2012, all rights reserved
 *
 * @author nh
 * Utility to show information when something goes wrong
 */
import sv from '@sv/core';
import * as events from './events';
import * as i18n from './i18n';

// i18n convenience method
var getText = function (key, args) {
  return i18n.getText('util.errorutil', key, args);
};

// Show an error message
var showErrorMessage = function (aTitle, aMessage) {
  events.trigger(events.types.notifyUser, {
    type: 'error',
    heading: aTitle,
    message: aMessage,
  });
};

// Utility function that displays error messages when something goes wrong
export const handleBackboneError = function (aModel, anXHR) {
  handleAjaxFailure(anXHR);
};

// Handle failures when communicating with a server
export const handleAjaxFailure = function (aResponse) {
  if (aResponse.readyState === 0) {
    showErrorMessage(
      getText('connectionErrorMessageTitle'),
      getText('connectionErrorMessage')
    );
    return;
  }

  // Show a localized error message if provided by the server
  if (aResponse.responseText !== undefined) {
    var responseText;

    if (typeof aResponse.responseText === 'object') {
      responseText = aResponse.responseText;
    } else {
      responseText = JSON.parse(aResponse.responseText);
    }

    if (
      responseText.title !== undefined &&
      responseText.message !== undefined
    ) {
      showErrorMessage(responseText.title, responseText.message);
      return;
    }
  }

  showErrorMessage(
    getText('errorMessageTitle'),
    getText('errorMessage', aResponse.status)
  );
};

sv.ErrorUtil = {
  handleBackboneError: handleBackboneError,
  handleAjaxFailure: handleAjaxFailure,
};
