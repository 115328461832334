/**
 * Copyright (C) SiteVision AB 2002-2018, all rights reserved
 *
 * @author albin
 */
import sv from '@sv/core';
import $ from '@sv/jquery';

const eventHandlers = {};
const eventPrefix = 'sv-event-';
const serverEventPrefix = 'server-';

export const types = {
  notifyUser: 'notify-user',
  updateRelativeDates: 'update-relative-dates',
  updateOembedLinks: 'update-oembed-links',
  updateLikables: 'update-likables',
  fileAdded: 'file-added',
  // used for events between task-portlet.js and calendar-portlet.js
  activityUpdated: 'activity-updated',
  activityCreated: 'activity-created',
  activityDeleted: 'activity-deleted',
  // used to send unmark new entries events from groupPage.js to group portlets (e.g. filelist-portlet.js, tasks-portlet.js)
  groupPageViewed: 'group-page-viewed',
  // used to close all 'other' popovers when a new pops up
  popoverCreated: 'popover-created',
  showMessages: 'show-messages',
};

export const on = function (eventType, handler, scope) {
  var prefixedEventName = eventPrefix + eventType,
    serverEvent = eventType && eventType.indexOf(serverEventPrefix) === 0;

  scope = scope || window;

  if (!eventHandlers[prefixedEventName]) {
    eventHandlers[prefixedEventName] = [];
  }

  eventHandlers[prefixedEventName].push({
    handler: handler,
    scope: scope,
  });

  if (serverEvent) {
    subscribeServerEvent(eventType);
  }
};

export const onServerEvent = function (eventType, handler, scope) {
  var prefixedEventName = eventPrefix + eventType;

  scope = scope || window;

  if (!eventHandlers[prefixedEventName]) {
    eventHandlers[prefixedEventName] = [];
  }

  eventHandlers[prefixedEventName].push({
    handler: handler,
    scope: scope,
  });

  return subscribeServerEvent(eventType);
};

export const off = function (eventType, handler) {
  var prefixedEventName = eventPrefix + eventType,
    serverEvent = eventType && eventType.indexOf(serverEventPrefix) === 0,
    eventTypeHandlers = eventHandlers[prefixedEventName];

  if (!eventTypeHandlers) {
    // no one bound to this event, return...
    return;
  }

  if (serverEvent) {
    unsubscribeServerEvent(eventType);
  }

  if (!handler) {
    // If no specific handler is supplied, we unbind all.
    eventTypeHandlers = [];
  } else {
    eventTypeHandlers = $.grep(eventTypeHandlers, function (element) {
      if (typeof element.handler.guid === 'undefined') {
        return element.handler !== handler;
      }
      return element.handler.guid !== handler.guid;
    });
  }
  eventHandlers[prefixedEventName] = eventTypeHandlers;
};

//Note trigger publishing to server via websocket not supported.
export const trigger = function (eventType) {
  var prefixedEventName = eventPrefix + eventType,
    eventTypeHandlers = eventHandlers[prefixedEventName],
    passedArguments;

  if (!eventTypeHandlers) {
    // no one bound to this event, return...
    return;
  }

  passedArguments = [];

  $.each(arguments, function (i, argument) {
    // Pass all arguments except the eventType
    if (i > 0) {
      passedArguments.push(argument);
    }
  });

  $.each(eventTypeHandlers, function (i, listener) {
    listener.handler.apply(listener.scope, passedArguments);
  });
};

var subscribeServerEvent = function (eventType) {
  var deferred = $.Deferred();

  var channel = eventType.substring(serverEventPrefix.length);

  if (channel === 'undefined') {
    return;
  }

  sv._serverEventChannels = sv._serverEventChannels || [];
  sv._serverEventChannels.push({ subscribe: channel });

  on('_server-event-connected-' + channel, function () {
    deferred.resolve();
    off('_server-event-connected-' + channel);
    off('_server-event-failed-' + channel);
  });

  on('_server-event-failed-' + channel, function () {
    deferred.reject();
    off('_server-event-connected-' + channel);
    off('_server-event-failed-' + channel);
  });

  return deferred.promise();
};

var unsubscribeServerEvent = function (eventType) {
  var channel = eventType.substring(serverEventPrefix.length);

  sv._serverEventChannels.push({ unsubscribe: channel });
};

export const handleServerSideEvent = function (channel, message) {
  var prefixedEventName = eventPrefix + serverEventPrefix + channel,
    eventTypeHandlers = eventHandlers[prefixedEventName];

  $.each(eventTypeHandlers, function (i, listener) {
    listener.handler.call(window, message);
  });
};

sv.Events = {
  on,
  off,
  onServerEvent,
  trigger,
  types,
  handleServerSideEvent,
};
