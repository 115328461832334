/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * Utility to handle modals
 *
 * @author Ola Vognild
 *
 */
import sv from '@sv/core';
import $ from '@sv/jquery';
import * as i18n from './i18n';
import { template, htmlEncode } from './templateutil';

var textTemplate = '<p class="env-text"><%= text %></p>';

var buttonTemplate =
  '<button <%= attributes %> class="env-button <%= classNames %>"><%= text %></button>';

var modalTemplate =
  '<div class="env-modal-dialog" id="<%= modalId %>" role="dialog" aria-hidden="true" tabindex="-1">' +
  '<div class="env-modal-dialog__dialog">' +
  '<section class="env-modal-dialog__content">' +
  '<header class="env-modal-dialog__header">' +
  '<div class="env-d--flex">' +
  '<h4 class="env-text env-modal-dialog__header__title"><%= title %></h4>' +
  '</div>' +
  '</header>' +
  '<div class="env-modal-dialog__body env-p-left--large env-p-top--large env-p-right--large">' +
  '<%= modalBody %>' +
  '</div>' +
  '<footer class="env-modal-dialog__footer env-modal-dialog__footer--right env-m-top--small">' +
  '<%= footerButtons %>' +
  '</footer>' +
  '</section>' +
  '</div>' +
  '</div>';

/* Helper functions */

function getText(key, args) {
  return i18n.getText('common', key, args);
}

function getNamespacedId(id) {
  return 'modal_' + id.replace('.', '_');
}

function getModal(aOptions) {
  // Default values
  var options = $.extend(
    {
      title: '',
      modalBody: '',
      footerButtons: '',
      titleHelp: '',
      errorContent: '',
    },
    aOptions
  );

  if (typeof options.modalBody === 'function') {
    options.modalBody = options.modalBody();
  }

  if (typeof options.modalBody === 'string') {
    return $(template(modalTemplate, options));
  } else if (options.modalBody instanceof $) {
    // If body is passed in as an object we need to append it.
    // To do so we need to reset options.modalBody to: ''
    options.modalBody = '';
    var $modal = $(template(modalTemplate, options));

    $modal.find('.env-modal-dialog__body').append(aOptions.modalBody);

    return $modal;
  } else {
    return $(template(modalTemplate, options));
  }
}

function getOkCancelButtons() {
  var okButton, cancelButton;

  okButton = template(buttonTemplate, {
    classNames: 'env-button--primary env-m-right--x-small',
    attributes: 'data-sv-confirm-button',
    text: getText('ok'),
  });
  cancelButton = template(buttonTemplate, {
    classNames: '',
    attributes: 'data-modal-dialog-dismiss',
    text: getText('cancel'),
  });

  return okButton + cancelButton;
}

function getDefaultButton() {
  return template(buttonTemplate, {
    classNames: 'env-button--primary',
    attributes: 'data-modal-dialog-dismiss',
    text: getText('ok'),
  });
}

function getFooterButtons(options) {
  var $buttons = '';

  $.each(options, function (index, elem) {
    $buttons += template(buttonTemplate, {
      attributes: elem.attributes ? elem.attributes : '',
      classNames: elem.classNames ? elem.classNames : '',
      text: elem.text ? htmlEncode(elem.text) : '',
    });
  });

  return $buttons;
}

function getBodyText(text) {
  return template(textTemplate, {
    text: htmlEncode(text),
  });
}

/* Modals */

export function showMessageModal(modalOptions) {
  var modalId = getNamespacedId(modalOptions.id);
  var $modal = getModal({
    modalId: modalId,
    title: modalOptions.title ? htmlEncode(modalOptions.title) : '',
    modalBody: modalOptions.text ? getBodyText(modalOptions.text) : '',
    footerButtons:
      modalOptions.type === 'confirm'
        ? getOkCancelButtons()
        : getDefaultButton(),
  });

  $modal.envDialog('show');
  $('body').append($modal);

  $modal.trigger('focus');

  $modal.on('click', '[data-sv-confirm-button]', function () {
    modalOptions &&
      modalOptions.onOk &&
      modalOptions.onOk($modal, getInputValues($modal));

    $modal.envDialog('hide');
  });

  $modal.one('hide.env-modal-dialog', function () {
    $modal.remove();
  });
}

export function showModal(modalOptions) {
  var modalId = getNamespacedId(modalOptions.id);
  var $footerButtons = modalOptions.buttons
    ? getFooterButtons(modalOptions.buttons)
    : getDefaultButton();
  var $modal = getModal({
    title: htmlEncode(modalOptions.title),
    modalBody: createModalBody(modalOptions.fields, modalOptions.customContent),
    modalId: htmlEncode(modalId),
    footerButtons: $footerButtons,
  });

  $modal.envDialog('show');
  $('body').append($modal);

  $modal.find('input,textarea').first().trigger('focus');
  $modal.find('[data-elastic]').elastic();

  $modal.on('click', '[data-sv-confirm-button]', function () {
    if (modalOptions.validate && !isValid($modal, modalOptions)) {
      return;
    }

    modalOptions &&
      modalOptions.onOk &&
      modalOptions.onOk($modal, getInputValues($modal));

    $modal.envDialog('hide');
  });

  $modal.one('hide.env-modal-dialog', function () {
    $modal.remove();
  });

  return $modal;
}

function addCharacterCounter(maxCharacters, $input) {
  $input.after(
    '<span class="sv-float-right" data-characters-left>' +
      (maxCharacters - $input.val().length) +
      '</span>'
  );

  $input.on('input', function (e) {
    var charactersLeft = maxCharacters - e.currentTarget.value.length;
    var $modal = $(e.currentTarget).closest('.env-modal-dialog');
    var $charactersLeft = $modal.find('[data-characters-left]');
    var $confirmButton = $modal.find('[data-sv-confirm-button]');

    if (charactersLeft < 0 || e.currentTarget.value.length <= 0) {
      $charactersLeft.addClass('sv-character-limit-exceeded');
      $confirmButton.prop('disabled', true);
    } else {
      $charactersLeft.removeClass('sv-character-limit-exceeded');
      $confirmButton.prop('disabled', false);
    }

    $charactersLeft.text(charactersLeft);
  });
}

function createModalBody(fields, customContent) {
  var $modalContent = $('<div/>', {
    class: 'env-form',
  });

  if (fields && fields.length) {
    fields.forEach(function (field) {
      var $field;

      if (field.type === 'textField') {
        $field = $('<input/>', field.attributes);
      }
      if (field.type === 'textArea') {
        $field = $('<textarea/>', field.attributes);
      }

      var $wrapperContainer = $('<div>', {
        class: 'env-form-element',
      });
      var $inputContainer = $('<div>', {
        class: 'env-form-element__control',
      });

      $modalContent.append($wrapperContainer);
      if (field.label) {
        var $label = $('<label>', {
          class: 'env-form-element__label',
        });
        $label.text(field.label);
        $wrapperContainer.append($label);
      }
      $wrapperContainer.append($inputContainer);
      $inputContainer.append($field);

      if (field.maxCharacterCount) {
        addCharacterCounter(field.maxCharacterCount, $field);
      }
      if (field.triggeredInput) {
        addTriggeredInput(field.triggeredInput, $field);
      }
      if (field.value) {
        $field.val(field.value);
      }
      if (field.elastic) {
        $field.attr('data-elastic', '');
      }
    });
  }

  if (customContent) {
    $modalContent.append($(customContent));
  }

  return $modalContent;
}

function isValid($modal, options) {
  var attrs = getInputValues($modal);
  var errors = options.validate(attrs);

  if (errors) {
    showErrors($modal, errors);
    return false;
  }

  return true;
}

function addTriggeredInput(triggeredInputOptions, $field) {
  var inputConfig = {
    triggers: {
      '@': {
        source: triggeredInputOptions.url,
        searchKey: 'query',
        allowWhiteSpace: true,
        maxWhiteSpace: 2,
        useCache: triggeredInputOptions.useCache !== false,
      },
    },
    listClassName: triggeredInputOptions.listClassName,
  };

  $field.triggeredInput(inputConfig);
  $field.val('@');

  if (triggeredInputOptions.inputCallback) {
    $field.on(
      'insert-text.triggeredInput',
      triggeredInputOptions.inputCallback
    );
  }
}

function getInputValues($modal) {
  var $inputs = $modal.find('input, textarea');
  var attrs = {};

  $inputs.each(function (i, input) {
    var $input = $(input);

    attrs[$input.attr('name')] = $input.val();
  });

  return attrs;
}

function showErrors($modal, errors) {
  removeErrors($modal);
  errors.forEach(function (error) {
    $modal
      .find('input[name=' + error.field + ']')
      .one('input', removeErrors.bind(this, $modal))
      .parent()
      .addClass('env-form-element--danger')
      .append(
        '<p class="env-color--danger error-message">' + error.message + '</p>'
      );
  });
}

function removeErrors($modal) {
  $modal
    .find('input[name]')
    .parent()
    .removeClass('env-form-element--danger')
    .find('.error-message')
    .remove();
}

sv.ModalUtil = {
  showModal,
  showMessageModal,
};
