/**
 * Copyright (C) SiteVision AB 2013, all rights reserved
 *
 * @author micke
 */
import sv from '@sv/core';

export const KEY = {
  BACKSPACE: 8,
  TAB: 9,
  RETURN: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  BREAK: 19,
  CAPS_LOCK: 20,
  ESC: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  INSERT: 45,
  DEL: 46,
  COMMA: 188,
};

export const getKeyCodeFromEvent = function (e) {
  return e.keyCode ? e.keyCode : e.which;
};

export const isNonTextModifiableKeyCode = function (keyCode) {
  switch (keyCode) {
    case KEY.TAB:
    case KEY.RETURN:
    case KEY.SHIFT:
    case KEY.CTRL:
    case KEY.ALT:
    case KEY.BREAK:
    case KEY.CAPS_LOCK:
    case KEY.ESC:
    case KEY.PAGE_UP:
    case KEY.PAGE_DOWN:
    case KEY.END:
    case KEY.HOME:
    case KEY.LEFT:
    case KEY.UP:
    case KEY.RIGHT:
    case KEY.DOWN:
    case KEY.INSERT:
      return true;
  }
  return false;
};

sv.KeyUtil = {
  KEY,
  getKeyCodeFromEvent,
  isNonTextModifiableKeyCode,
};
