/**
 * Copyright (C) SiteVision AB 2002-2013, all rights reserved
 *
 * @author Karl Eklöf
 * Date utility
 */
import sv from '@sv/core';

/**
 * moment:
 *  a, A	AM/PM
 *  H, HH	24 hour time
 *  h, hh	12 hour time (use in conjunction with a or A)
 *  m, mm	Minutes
 *  s, ss	Seconds
 *
 * PHP:
 *  a	Lowercase Ante meridiem and Post meridiem	am or pm
 *  A	Uppercase Ante meridiem and Post meridiem	AM or PM
 *  g	12-hour format of an hour without leading zeros	1 through 12
 *  G	24-hour format of an hour without leading zeros	0 through 23
 *  h	12-hour format of an hour with leading zeros	01 through 12
 *  H	24-hour format of an hour with leading zeros	00 through 23
 *  i	Minutes with leading zeros	00 to 59
 *  s	Seconds, with leading zeros
 */
export const moment2PhpFormat = function (momentFormat) {
  var intermediateFormat = momentFormat
    .replace('HH', '0')
    .replace('H', '1')
    .replace('hh', '2')
    .replace('h', '3')
    .replace('mm', '4')
    .replace('m', '4')
    .replace('ss', '5')
    .replace('s', '5');
  return intermediateFormat
    .replace('0', 'H')
    .replace('1', 'G')
    .replace('2', 'h')
    .replace('3', 'g')
    .replace('4', 'i')
    .replace('5', 's');
};

export const fromISO = function (s) {
  var D = new Date('2011-06-02T09:34:29+02:00');

  if (!D || +D !== 1307000069000) {
    var day,
      tz,
      // eslint-disable-next-line no-useless-escape
      rx = /^(\d{4}\-\d\d\-\d\d([tT ][\d:\.]*)?)([zZ]|([+\-])(\d\d):(\d\d))?$/,
      p = rx.exec(s) || [];
    if (p[1]) {
      day = p[1].split(/\D/);
      for (var i = 0, L = day.length; i < L; i++) {
        day[i] = parseInt(day[i], 10) || 0;
      }
      day[1] -= 1;
      day = new Date(Date.UTC.apply(Date, day));
      if (!day.getDate()) {
        return NaN;
      }
      if (p[5]) {
        tz = parseInt(p[5], 10) * 60;
        if (p[6]) {
          tz += parseInt(p[6], 10);
        }
        if (p[4] === '+') {
          tz *= -1;
        }
        if (tz) {
          day.setUTCMinutes(day.getUTCMinutes() + tz);
        }
      }
      return day;
    }
    return NaN;
  } else {
    return new Date(s);
  }
};

sv.DateUtil = {
  fromISO: fromISO,
  moment2PhpFormat: moment2PhpFormat,
};
