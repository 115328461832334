/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * language dependent text extractor
 *
 * @author micke
 */
import sv from '@sv/core';
import $ from '@sv/jquery';
import * as log from './log';

let lang;
const defaultLang = 'en';

(function () {
  // detect system language
  var locale = $('html').attr('lang');
  if (locale.indexOf('-') !== -1) {
    lang = locale.toLowerCase().substring(0, locale.indexOf('-'));
  } else {
    lang = locale.toLowerCase();
  }
  log.info(this, 'Using system language: ' + lang);
})();

export const getLanguage = function () {
  return lang;
};

export const getText = function (pkg, property, args) {
  try {
    var properties = getProperties(pkg, sv.i18n),
      result = properties[lang][property];

    if (!result) {
      log.warn(
        this,
        'Unable to find property: ' +
          pkg +
          '.' +
          lang +
          '.' +
          property +
          ', trying default language: ' +
          defaultLang
      );
      return properties[defaultLang][property] || property;
    }

    if (args) {
      if (!Array.isArray(args)) {
        args = [args];
      }

      return result.replace(/\{(\d+)\}/g, function () {
        return args[arguments[1]];
      });
    }

    return result;
  } catch (e) {
    try {
      return properties[defaultLang][property] || property;
    } catch (e1) {
      /*log.error( //TODO: uncomment when done!
        this,
        'Unable to find property: ' + pkg + '.' + lang + '.' + property
      );*/
      return property;
    }
  }
};

var getProperties = function (path, context) {
  path = path.split('.');
  for (var i = 0; i < path.length; i++) {
    context = context[path[i]];
  }

  return context;
};

sv.i18n = {
  getText,
  getLanguage,
};
