/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 */
import $ from '@sv/jquery';

if (!$.fn.bsPopoverAriaButton) {
  $.fn.bsPopoverAriaButton = function () {
    var $button = this;

    function setPressedState(pressed) {
      $button.attr('aria-pressed', pressed);
    }

    $button
      .on('hide.bs.modal', function () {
        setPressedState(false);
      })
      .on('show.bs.modal', function () {
        setPressedState(true);
      })
      // Enable space when using role=button!
      .on('keydown', function (e) {
        if (e.keyCode === 32) {
          e.preventDefault();
        }
      })
      .on('keyup', function (e) {
        if (e.keyCode === 32) {
          this.trigger('click');
        }
      })
      // Set initial state and attributes
      .attr({
        role: 'button',
      });

    return this;
  };
}
