/**
 * Copyright (C) SiteVision AB 2002-2019, all rights reserved
 *
 * @author Karl Eklöf
 */
import sv from '@sv/core';
import $ from '@sv/jquery';

function getObjectId(id) {
  if (!id) {
    return null;
  }

  return id.substring('svid'.length).replace('_', '.');
}

$(function () {
  if (!sv.nodeIsReadOnly) {
    $('.sv-portlet, .sv-text-portlet, .sv-custom-module').on(
      'dblclick',
      function (e) {
        if (e.shiftKey && !top.SiteVisionEditor) {
          if ($(e.currentTarget).hasClass('sv-text-portlet')) {
            top.location.href = '/edit/' + sv.PageContext.pageId;
          } else {
            top.location.href =
              '/edit/' +
              sv.PageContext.pageId +
              '/' +
              getObjectId($(e.currentTarget).attr('id')) +
              '/settings';
          }
        }
      }
    );
  }
});
