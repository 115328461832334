/**
 * Copyright (C) SiteVision AB 2002-2012, all rights reserved
 *
 * @author albin
 * Utility to retrieve information about the navigator i.e. the users browser
 */
import sv from '@sv/core';
import $ from '@sv/jquery';

const windowPlatform = window.navigator.platform;

var checkPlatform = function (platform) {
  return windowPlatform.toLowerCase().indexOf(platform) !== -1;
};

var isMac = checkPlatform('mac');
var isWindows = checkPlatform('win');
var isLinux = checkPlatform('linux');

var isIOSDevice = (function () {
  return navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false;
})();

var isJavaInstalled = false;

var isMobileDevice = (function () {
  var userAgent = window.navigator.userAgent;
  return (
    /iPhone/i.test(userAgent) ||
    /iPod/i.test(userAgent) ||
    /iPad/i.test(userAgent) ||
    /mobile/i.test(userAgent)
  );
})();

// Supported since IE10 we don't care about the rest.
var supportsPlaceholder = true;

let isTouchDevice;
var setIsTouchDevice = function () {
  var isTouchEnabled = (function () {
    if ('ontouchstart' in window) {
      return true;
    }

    if (window.DocumentTouch && document instanceof window.DocumentTouch) {
      return true;
    }

    return window.matchMedia && window.matchMedia('(pointer: coarse)').matches;
  })();
  $('html').addClass(isTouchEnabled ? 'sv-touch' : 'sv-no-touch');
  sv.ClientUtil.isTouchDevice = isTouchDevice = isTouchEnabled;
};

// Delay execution until page is loaded.
setTimeout(setIsTouchDevice, 0);

sv.ClientUtil = {
  isMac,
  isWindows,
  isLinux,
  isMobileDevice,
  supportsPlaceholder,
  isTouchDevice,
  isIOSDevice,
  isJavaInstalled,
};
